import Vue from "vue";
import axios from "axios";
import store from "@/store";
import router from "./../router/index";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  },
});

service.interceptors.request.use(
  (config) => {
    if (store.getters["user/getToken"]) {
      config.headers[
        "Authorization"
      ] = `Bearer ${store.getters["user/getToken"]}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    store.dispatch("ClearServerErrors");
    return response;
  },
  (error) => {
    console.log(error);
    if (error.response.status === 401) {
      store.commit("user/SET_TOKEN", null);
      router.push({ name: "Login" });
    }
    if (error.response.status === 422) {
      const { errors } = error.response.data;
      store.dispatch("SetServerErrors", errors);
    }
    return Promise.reject(error.response.data);
  }
);

Vue.prototype.axios = service;
export default service;
