<template>
  <div class="main-header">
    <div @click="sideBarToggle" class="menu-toggle">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div style="margin: auto"></div>
    <div class="header-part-right">
      <router-link
        v-if="
          currentUserPermissions && currentUserPermissions.includes('Pos_view')
        "
        class="btn btn-outline-primary tn-sm btn-rounded"
        to="/app/pos"
      >
        <span class="ml-1">POS</span>
      </router-link>
      <!-- Full screen toggle -->
      <i
        class="i-Full-Screen header-icon d-none d-sm-inline-block"
        @click="handleFullScreen"
      ></i>
      <!-- Grid menu Dropdown -->
      <div class="dropdown" @click="SetLocal">
        <i
          class="i-Globe text-muted header-icon"
          role="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        ></i>

        <!-- <vue-perfect-scrollbar
            :settings="{ suppressScrollX: true, wheelPropagation: false }"
            ref="myData"
            class="dropdown-menu-right rtl-ps-none notification-dropdown ps scroll"
          >
            <div class="menu-icon-grid">

            </div>
          </vue-perfect-scrollbar> -->
      </div>
      <!-- Notificaiton -->
      <div class="dropdown">
        <b-dropdown
          id="dropdown-1"
          text="Dropdown Button"
          class="m-md-2 badge-top-container d-none d-sm-inline-block"
          toggle-class="text-decoration-none"
          no-caret
          variant="link"
        >
          <template slot="button-content">
            <span class="badge badge-primary" v-if="notifs_alert > 0">1</span>
            <i class="i-Bell text-muted header-icon"></i>
          </template>
          <!-- Notification dropdown -->
          <vue-perfect-scrollbar
            :settings="{ suppressScrollX: true, wheelPropagation: false }"
            :class="{ open: getSideBarToggleProperties.isSideNavOpen }"
            ref="myData"
            class="dropdown-menu-right rtl-ps-none notification-dropdown ps scroll"
          >
            <div class="dropdown-item d-flex" v-if="notifs_alert > 0">
              <div class="notification-icon">
                <i class="i-Bell text-primary mr-1"></i>
              </div>
              <div
                class="notification-details flex-grow-1"
                v-if="
                  currentUserPermissions &&
                  currentUserPermissions.includes('Reports_quantity_alerts')
                "
              >
                <router-link tag="a" to="/app/reports/quantity_alerts">
                  <p class="text-small text-muted m-0">
                    {{ notifs_alert }} {{ $t('ProductQuantityAlerts') }}
                  </p>
                </router-link>
              </div>
            </div>
          </vue-perfect-scrollbar>
        </b-dropdown>
      </div>
      <!-- Notificaiton End -->

      <!-- User avatar dropdown -->
      <div class="dropdown">
        <b-dropdown
          id="dropdown-1"
          text="Dropdown Button"
          class="m-md-2 user col align-self-end d-md-block"
          toggle-class="text-decoration-none"
          no-caret
          variant="primary"
        >
          <template slot="button-content">
            <i class="i-Lock-User h5 p-2"></i>
          </template>

          <div class="dropdown-menu-right" aria-labelledby="userDropdown">
            <div class="dropdown-header">
              <i class="i-Lock-User mr-1"></i>
              <span>Name</span>
            </div>
            <router-link to="/app/profile" class="dropdown-item">{{
              $t('profil')
            }}</router-link>
            <!--
v-if=" currentUserPermissions &&
currentUserPermissions.includes('setting_system') "


             -->
            <router-link
              to="/app/settings/System_settings"
              class="dropdown-item"
              >{{ $t('Settings') }}</router-link
            >
            <a class="dropdown-item" href="#" @click.prevent="logoutUser">{{
              $t('logout')
            }}</a>
          </div>
        </b-dropdown>
      </div>
    </div>
  </div>

  <!-- header top menu end -->
</template>
<script>
let toggleFullScreen = () => {
  let doc = window.document;
  let docEl = doc.documentElement;
  let requestFullScreen =
    docEl.requestFullscreen ||
    docEl.mozRequestFullScreen ||
    docEl.webkitRequestFullScreen ||
    docEl.msRequestFullscreen;
  let cancelFullScreen =
    doc.exitFullscreen ||
    doc.mozCancelFullScreen ||
    doc.webkitExitFullscreen ||
    doc.msExitFullscreen;
  if (
    !doc.fullscreenElement &&
    !doc.mozFullScreenElement &&
    !doc.webkitFullscreenElement &&
    !doc.msFullscreenElement
  ) {
    requestFullScreen.call(docEl);
  } else {
    cancelFullScreen.call(doc);
  }
};

// import Sidebar from "./Sidebar";
import { isMobile } from 'mobile-device-detect';
import { mapGetters, mapActions } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
// import { setTimeout } from 'timers';
import FlagIcon from 'vue-flag-icon';

export default {
  mixins: [clickaway],
  components: {
    FlagIcon,
  },

  data() {
    return {
      langs: ['en', 'fr'],

      isDisplay: true,
      isStyle: true,
      isSearchOpen: false,
      isMouseOnMegaMenu: true,
      isMegaMenuOpen: false,
      is_Load: false,
      // alerts:0,
    };
  },

  computed: {
    ...mapGetters([
      'currentUser',
      'getSideBarToggleProperties',
      'currentUserPermissions',
      'notifs_alert',
    ]),
  },

  methods: {
    ...mapActions([
      'changeSecondarySidebarProperties',
      'changeSidebarProperties',
      'changeThemeMode',
      'logout',
      'changeThemeRtl',
    ]),

    // logoutUser() {
    //   this.$store.dispatch('logout');
    // },

    SetLocal() {
      let langWillToSet = this.$i18n.locale === 'en' ? 'ar' : 'en';
      console.log('SetLocal', this.$i18n.locale);
      this.$i18n.locale = langWillToSet;
      if (langWillToSet === 'ar') {
        this.changeThemeRtl(true);
      } else {
        this.changeThemeRtl(false);
      }
      this.$store.dispatch('language/setLanguage', langWillToSet);
    },

    handleFullScreen() {
      toggleFullScreen();
    },
    logoutUser() {
      this.axios.post('/admin/logout').then((res) => {
        this.$store.dispatch('user/logout');
        this.$router.push('/login');
        this.makeToast('success', 'Successfully logged out', 'Success');
      });

      // this.logout();
    },

    closeMegaMenu() {
      this.isMegaMenuOpen = false;
    },
    toggleMegaMenu() {
      this.isMegaMenuOpen = !this.isMegaMenuOpen;
    },
    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen;
    },

    sideBarToggle(el) {
      this.changeSidebarProperties();
    },
  },
};
</script>
